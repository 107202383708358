
import { useContext, useEffect, useState } from "react";
import Loader from "../../components/loader";
import MaterialCard from "../../components/material_card";
import NavHeader from "../../components/nav_header";
import { DataCtx } from "../../context";
import User from '../../models/user.model';
import { getUser } from "../../repository/authentication";
import { getAllUsers } from "../../repository/functions";
import EditUserModal from "./modals/edit_user_modal";

export default function UsersTab() {

  const dataCtx = useContext(DataCtx);
  const [users, setUsers] = dataCtx.users;

  const [userToEdit, setUserToEdit] = useState<User>();

  const currentUser = getUser();

  useEffect(() => {
    getAllUsers().then((users) => {
      setUsers!(users.filter((u) => u.email !== currentUser!.email))
    })
  }, [currentUser, setUsers])

  if (!users) return <Loader />

  return (
    <div className='m-4 flex flex-col items-center w-full max-w-md'>
      <NavHeader
        shouldRenderBack={false}
        shouldRenderAdd={false}
        title="Users"
      />

      {users.map((user) => renderUserRow({ user, onClick: () => setUserToEdit(user) }))}

      {userToEdit && <EditUserModal user={userToEdit} onDismiss={() => setUserToEdit(undefined)} />}
    </div >
  )
}

interface UserRowProperties {
  user: User
  onClick: () => void;
}

function renderUserRow(props: UserRowProperties) {
  return <MaterialCard isFullWidth={true} key={props.user.uid} onClick={props.onClick}>
    <div className="flex flex-row w-full justify-between items-center">
      <span>{props.user.email}</span>
      <i className="fa-solid fa-chevron-right text-gray-400"></i>
    </div>
  </MaterialCard>
}
