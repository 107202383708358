import User from '../models/user.model';
import { getUser, isUserAdmin } from './authentication';

export async function getAllUsers() {
  const currentUser = getUser();

  if (!currentUser || !isUserAdmin()) {
    throw new Error('Not authorized');
  }

  const token = await currentUser.getIdToken();

  const data = await fetch('https://getusers-725p5js2lq-uc.a.run.app', {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  });

  const { users } = await data.json();
  return users as User[];
}

export async function deleteUser(uid: string) {
  const currentUser = getUser();

  if (!currentUser || !isUserAdmin()) {
    throw new Error('Not authorized');
  }

  const token = await currentUser.getIdToken();

  const data = await fetch('https://deleteuser-725p5js2lq-uc.a.run.app', {
    method: 'POST',
    headers: { Authorization: `Bearer ${token}` },
    body: JSON.stringify({ uid }),
  });

  return data.status === 204;
}

export async function getAircraftTypeId(registrationNumber: string) {
  const token = await getUser()?.getIdToken();

  if (!token) return undefined;

  const data = await fetch(
    'https://getaircrafttypeid-725p5js2lq-uc.a.run.app',
    {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: JSON.stringify({ registrationNumber }),
    },
  );

  if (data.status !== 200) return undefined;

  const details: { aircraftTypeId?: string } = await data.json();
  return details.aircraftTypeId;
}
