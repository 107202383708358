import { Timestamp } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import MaterialCard from "../../components/material_card";
import Modal from "../../components/modal";
import NavHeader from "../../components/nav_header";
import { DataCtx } from "../../context";
import Aircraft from "../../models/aircraft.model";
import Flight from "../../models/flights.model";
import MaintenanceItem from "../../models/maintenance_item.model";
import Milestone from "../../models/milestone.model";
import { createMilestone, deleteAircraft, deleteFlight, deleteMilestone, getFlights, getMaintenanceItems, getMilestones, updateAircraft } from "../../repository/database";
import AddMaintenanceItemModal from "./modals/add_maintenance_item_modal";

export function AircraftDetails() {
  const { regNum } = useParams();

  const [isAddMaintenance, setAddMaintenance] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [maintenanceItems, setMaintenanceItems] = useState<Map<string, MaintenanceItem>>();
  const [milestones, setMilestones] = useState<Milestone[]>();
  const [flights, setFlights] = useState<Flight[]>();

  const dataCtx = useContext(DataCtx);
  const [allAircraft, setAllAircraft] = dataCtx.aircraft;
  const [aircraftTypes] = dataCtx.aircraftTypes;

  const aircraft = allAircraft?.find((a) => a.registrationNumber === regNum);
  const aircraftType = aircraftTypes?.find((at) => at.id === aircraft?.aircraft_type_id);

  useEffect(() => {
    if (!aircraft || !aircraftType) return;

    getMaintenanceItems(aircraft.aircraft_type_id).then((ms) => {
      const items = ms.reduce((acc, it) => acc.set(it.id!, it), new Map<string, MaintenanceItem>());
      setMaintenanceItems(items);
    });
    getMilestones(aircraft.registrationNumber).then(setMilestones);
    getFlights(aircraft.registrationNumber).then(setFlights);
  }, [aircraft, aircraftType]);

  const navigator = useNavigate()

  if (!aircraft || !aircraftType || !maintenanceItems || !milestones || !flights || isLoading) {
    return <Loader />
  }

  const fieldLabel = 'text-xs '
  const fieldValue = 'text-md font-semibold'
  const fieldSet = 'flex flex-col items-start flex-1 basis-1/2 py-2'

  return (
    <div className='w-full max-w-xl flex flex-col items-center'>
      <p className="font-bold text-xl self-center ">Details</p>
      <div className='flex flex-col w-full shadow-md rounded-lg border p-4 m-2 mb-8 group relative'>
        <div className='flex flex-row justify-between p4 flex-wrap'>
          <div className={fieldSet}>
            <span className={fieldLabel}>Registration Number:</span>
            <span className={fieldValue}>{aircraft?.registrationNumber}</span>
          </div>
          <div className={fieldSet}>
            <p className={fieldLabel}>Owner:</p>
            <p className={fieldValue}>{aircraft?.owner?.nameFirst} {aircraft?.owner?.nameLast}</p>

            <span className={fieldLabel}><i className="fa-solid fa-envelope"></i> : {aircraft?.owner?.email}</span>
            <span className={fieldLabel}><i className="fa-solid fa-phone"></i> : {aircraft?.owner?.phone}</span>
          </div>
          <div className={fieldSet}>
            <span className={fieldLabel}>Aircraft Model:</span>
            <button
              className="font-bold text-blue-500 hover:underline"
              onClick={() => navigator(`/aircraft_types/${aircraftType.id}/details`)
              }>
              <span className={fieldValue}>{aircraft?.year} {aircraftType?.manufacturer} {aircraftType?.model}</span>
            </button>
          </div>
          <div className={fieldSet}>
            <span className={fieldLabel}>Total Hours:</span>
            <span className={fieldValue}>{aircraft?.total_hours.toLocaleString()}</span>
          </div>
        </div>

        <div
          className="
            absolute right-0 mr-4 invisible opacity-0 
            group-hover:opacity-100 group-hover:visible transition-all 
            duration-300 flex flex-row space-x-6
          "
        >
          <button onClick={() => setIsEditing(true)}>
            <i className="fa-solid fa-pen text-blue-500"></i>
          </button>
          <button onClick={() => setIsConfirmDelete(true)}>
            <i className="fa-solid fa-trash text-red-500"></i>
          </button>
        </div>
      </div>

      <NavHeader
        shouldRenderBack={false}
        shouldRenderAdd={true}
        onClickAdd={() => setAddMaintenance(true)}
        title={"Maintenance Milestones"}
      />

      {
        maintenanceItems && milestones
          ?.filter((m) => maintenanceItems.has(m.maintenance_item_id))
          .map(milestone => ({ milestone, maintenanceItem: maintenanceItems!.get(milestone.maintenance_item_id)! }))
          .map((mm) => renderMilestoneCell(aircraft, mm, async () => {
            await deleteMilestone(aircraft.registrationNumber, mm.milestone.id!)
            setMilestones(milestones.filter((m) => m.id !== mm.milestone.id));
          }))
      }


      <p className="font-bold text-xl self-center mt-8 mb-4">Flights</p>
      {
        flights && flights
          .sort((fl1, fl2) => {
            const fl1_start = fl1.flight_start.timestamp.toDate().getTime();
            const fl2_start = fl2.flight_start.timestamp.toDate().getTime();
            return fl2_start - fl1_start
          })
          .map(f => renderFlightCard(f, async () => {
            await deleteFlight(aircraft.registrationNumber, f.id!)
            setFlights(flights.filter(ff => ff.id !== f.id))
          }))
      }

      {isAddMaintenance && <AddMaintenanceItemModal
        maintenanceItems={Array.from(maintenanceItems?.values() ?? [])}
        onDismiss={() => setAddMaintenance(false)}
        onSubmit={async (milestone) => {
          const aircraftId = aircraft?.registrationNumber ?? '';
          await createMilestone(aircraftId, milestone)
          getMilestones(aircraftId).then(setMilestones)
          setAddMaintenance(false)
        }}
        airframeHours={aircraft?.total_hours ?? 0}
      />
      }

      {isEditing && <EditAircraftHours aircraft={aircraft} onDismiss={() => setIsEditing(false)} />}
      {isConfirmDelete && <Modal>
        <div className="max-w-sm bg-white rounded-lg p-4">
          <div className="flex flex-col items-center w-full space-y-4">
            <p className="text-red-500 font-bold text-lg">Delete Aircraft?</p>
            <p className="text-sm text-center">This action is irreversible. Are you sure you want to delete this aircraft?</p>

            <div className="flex flex-row space-x-8">
              <button
                onClick={() => setIsConfirmDelete(false)}
                className="rounded bg-blue-500 font-bold text-sm text-white px-4 py-2 shadow-md">Go Back
              </button>
              <button
                onClick={async () => {
                  setIsConfirmDelete(false)
                  setIsLoading(true)
                  const success = await deleteAircraft(aircraft.registrationNumber);
                  if (success) {
                    setAllAircraft!(allAircraft!.filter((a) => a.registrationNumber !== aircraft.registrationNumber));
                    navigator('/aircraft');
                  } else {
                    setIsLoading(false);
                  }
                }}
                className="rounded bg-red-500 font-bold text-sm text-white px-4 py-2 shadow-md">Delete
              </button>
            </div>
          </div>

        </div>
      </Modal>}

    </div >

  )
}

function renderMilestoneCell(
  aircraft: Aircraft,
  details: { milestone: Milestone, maintenanceItem: MaintenanceItem },
  onClickDelete: () => void,
) {
  const { milestone, maintenanceItem } = details;

  let amount_total: number;
  let amount_completed: number;

  if (maintenanceItem.type === "wear-out") {
    amount_total = maintenanceItem.wear_out_hours!;
    amount_completed = aircraft.total_hours - milestone.wear_out_start!;
  } else {
    amount_total = maintenanceItem.time_out_days!;
    const diffMilliseconds = Date.now() - new Date(milestone.time_out_start!).getTime();
    const millisecondsInDay = 1000 * 60 * 60 * 24
    amount_completed = Math.floor(diffMilliseconds / millisecondsInDay);
  }

  let percent_complete = amount_completed / amount_total;

  const isWearOut = maintenanceItem.type === 'wear-out';
  const unit = isWearOut ? "hrs" : "days"

  let barColor;
  if (amount_completed >= amount_total) {
    barColor = "bg-red-700"
    percent_complete = 1
  } else if (amount_completed >= (amount_total * 0.75)) {
    barColor = "bg-yellow-500"
  } else {
    barColor = "bg-blue-600"
  }

  return <div
    key={milestone.id}
    className='flex flex-row justify-between items-center w-full max-w-xl shadow-md rounded-lg border p-4 m-2 group relative'
  >
    <span className='font-semibold text-base'>{maintenanceItem.name}</span>

    <div className='flex flex-col w-64 items-center mr-8'>
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-300">
        <div className={`${barColor} h-2.5 rounded-full`} style={{ width: Math.max(4, (percent_complete * 100)) + "%" }}></div>
      </div>
      <span className='text-sm mt-1'>{amount_completed.toLocaleString()} {unit} / {amount_total.toLocaleString()} {unit}</span>
    </div>
    <button
      onClick={onClickDelete}
      className="absolute right-0 mr-4 invisible opacity-0 group-hover:opacity-100 group-hover:visible transition-all duration-300 "
    >
      <i className="fa-solid fa-trash text-red-500"></i>
    </button>
  </div>
}

function renderFlightCard(flight: Flight, onClickDelete: () => void) {
  let origin: string | undefined;
  let destination: string | undefined;

  if (flight.flight_type === "v2") {
    if (flight.is_active) {
      return <MaterialCard isFullWidth={true} key={flight.id}>
        <div className="flex flex-col items-center group relative text-sm">
          <span className="text-red-500 font-bold">In Flight...</span>
          <span className="font-light text-center">{flight.flight_start.locDescription}</span>
        </div>
      </MaterialCard>
    }
    origin = flight.flight_start.location ?? "Unknown";
    destination = flight.flight_end?.location ?? "Unknown";
  } else {
    origin = flight.flight_start.locDescription ?? "Unknown";
    destination = flight.flight_end?.locDescription ?? "Unknown"
  }

  return <MaterialCard isFullWidth={true} key={flight.id}>
    <div className="flex flex-row justify-between items-center group relative text-sm px-2">

      <div className="flex flex-col items-center">
        <span className="font-bold text-center">{origin}</span>
        <span className="text-xs">{renderTimestamp(flight.flight_start.timestamp)}</span>
      </div>

      <i className="fa-solid fa-plane-departure pr-2 text-xs ml-6 mr-4"></i>
      <div className="flex-1 h-0.5 bg-green-700 rounded"></div>
      <i className="fa-solid fa-plane-arrival pr-2 text-xs mr-6 ml-4"></i>

      <div className="flex flex-col items-center">
        <span className="font-bold text-center">{flight.is_active ? "Flight active..." : destination}</span>
        <span className="text-xs">
          {flight.is_active
            ? <span className="text-red-500">CURRENTLY ACTIVE</span>
            : <span className="">{renderTimestamp(flight.flight_end?.timestamp) ?? "N/A"}</span>
          }
        </span>
      </div>

      <button
        onClick={onClickDelete}
        className="absolute right-0 top-0 mr-0 mt-0 invisible opacity-0 group-hover:opacity-100 group-hover:visible transition-all duration-300 "
      >
        <i className="fa-solid fa-trash text-red-500"></i>
      </button>

    </div>
  </MaterialCard >
}

function renderTimestamp(timestamp?: Timestamp | undefined) {
  return timestamp?.toDate().toLocaleString('en-us', { timeZone: 'America/New_York', month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "2-digit", hour12: true })
}

function EditAircraftHours(props: { aircraft: Aircraft, onDismiss: () => void }) {
  const { aircraft, onDismiss } = props;

  const { aircraft: [allAircraft, setAllAircraft] } = useContext(DataCtx);

  const [hours, setHours] = useState(aircraft.total_hours)

  return <Modal>
    <div className="max-w-sm w-full">
      <MaterialCard isFullWidth={true} >
        <div className="flex flex-col w-full">
          <div className="text-center relative">
            <span className="text-md font-bold">Edit Aircraft Hours</span>

            <button
              className="absolute right-0"
              onClick={() => onDismiss()}
            >
              <i className="fa-regular fa-circle-xmark"></i>
            </button>
          </div>
          <form onSubmit={async (e) => {
            e.preventDefault();
            const newAircraft = {
              ...aircraft,
              total_hours: hours,
            }

            await updateAircraft(aircraft.registrationNumber, newAircraft)

            const updatedAllAircraft = allAircraft!.map((a) => {
              return a.registrationNumber === newAircraft.registrationNumber ? newAircraft : a
            });
            setAllAircraft!(updatedAllAircraft);
            onDismiss();
          }}>
            <input
              className="rounded-lg border-gray-300 border px-2 py-0.5 mt-2 mb-3 w-full self-center"
              type="number" step="0.1" value={hours} onChange={(e) => setHours(+e.target.value)}
            />

            <input
              className="bg-blue-500 rounded-lg shadow-md w-full py-1 text-white font-bold text-sm cursor-pointer"
              type="submit" value={"Save"}
            />
          </form>
        </div>
      </MaterialCard>
    </div>
  </Modal>
}