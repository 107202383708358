
interface NavHeaderProps {
  shouldRenderBack: boolean;
  shouldRenderAdd: boolean;
  onClickBack?: () => void;
  onClickAdd?: () => void;
  title: string;
}

export default function NavHeader(props: NavHeaderProps) {
  return <div className="flex flex-row justify-center items-center self-center relative max-w-md w-full mb-4">
    {props.shouldRenderBack && <span
      onClick={props.onClickBack}
      className="text-blue-500 absolute left-0 cursor-pointer">
      <i className="fa-solid fa-chevron-left"></i> Back
    </span>}
    <span className="font-bold text-xl">{props.title}</span>
    {props.shouldRenderAdd && <i
      onClick={props.onClickAdd}
      className="fa-solid fa-plus text-blue-500 absolute right-0 cursor-pointer"></i>}
  </div>
}