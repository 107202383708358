import 'firebaseui/dist/firebaseui.css';
import { useEffect } from "react";
import { startAuth } from "../../repository/authentication";

export default function Landing() {
  useEffect(() => {
    startAuth();
  })
  return <div className="self-stretch h-full align-center m-16">
    <div id="firebaseui-auth-container" ></div>
  </div>
}