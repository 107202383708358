import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/loader";
import MaterialCard from "../../components/material_card";
import { DataCtx } from "../../context";
import AircraftType from "../../models/aircraft_type.model";
import MaintenanceItem from "../../models/maintenance_item.model";
import { createMaintenanceItem, deleteAircraftType, deleteMaintenanceItem, getMaintenanceItems } from "../../repository/database";
import CreateMaintenanceItemModal from "./modals/create_maintenance_item_modal";

export default function AircraftTypeDetails() {
  const { aircraftTypeId } = useParams();

  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [aircraftType, setAircraftType] = useState<AircraftType>()
  const [maintenanceItems, setMaintenanceItems] = useState<MaintenanceItem[]>();

  const context = useContext(DataCtx);
  const [aircraftTypes, setAircraftTypes] = context.aircraftTypes

  useEffect(() => {
    const aircraftType = aircraftTypes?.find((at) => at.id === aircraftTypeId)
    setAircraftType(aircraftType);

    getMaintenanceItems(aircraftTypeId ?? '')
      .then(setMaintenanceItems)

  }, [aircraftTypeId, aircraftTypes])

  const navigator = useNavigate()

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="flex flex-col items-center w-full max-w-sm">
      <MaterialCard isFullWidth={true}>
        <div className="flex flex-row space-x-16 mx-auto w-full max-w-xs justify-center group relative">
          <div className="">
            <p className="text-xs">Manufacturer</p>
            <span className="font-bold">{aircraftType?.manufacturer}</span>
          </div>
          <div>
            <p className="text-xs">Model</p>
            <span className="font-bold">{aircraftType?.model}</span>
          </div>
          <button
            className="absolute right-0 top-0 invisible duration-300 group-hover:visible group-hover:opacity-100 opacity-0"
            onClick={async () => {
              setIsLoading(true)
              const success = await deleteAircraftType(aircraftType?.id!);

              if (success) {
                setAircraftTypes!(aircraftTypes!.filter((at) => at.id !== aircraftType?.id))
                navigator("/aircraft_types")
              } else {
                setIsLoading(false)
              }
            }}
          >
            <i className="fa-solid fa-trash text-red-500"></i>
          </button>
        </div>
      </MaterialCard>

      <div className="relative w-full justify-center items-center flex flex-col self-center max-w-sm">
        <p className="my-4 font-bold text-xl">Maintenance Items</p>
        <i onClick={() => setShowModal(true)} className="absolute right-0 fa-solid fa-plus cursor-pointer text-blue-500"></i>
      </div>
      {maintenanceItems?.map((m) => maintentanceItemCell(m, async () => {
        setIsLoading(true)
        await deleteMaintenanceItem(aircraftType?.id!, m.id!)
        setMaintenanceItems(maintenanceItems.filter((mi) => mi.id !== m.id))
        setIsLoading(false)
      }))}

      {showModal && <CreateMaintenanceItemModal setShowModal={setShowModal} onCreateItem={async (item) => {
        setIsLoading(true)
        await createMaintenanceItem(aircraftTypeId ?? '', item);
        const maintenanceItem = await getMaintenanceItems(aircraftTypeId ?? '');
        setMaintenanceItems(maintenanceItem);
        setShowModal(false);
        setIsLoading(false)
      }} />}
    </div>
  )
}


function maintentanceItemCell(m: MaintenanceItem, onDelete: () => void) {
  const isWearOut = m.type === 'wear-out'
  return <div key={m.id} className="w-full max-w-sm group transform transition ">
    <MaterialCard>
      <div className="flex flex-row justify-between items-center relative">
        <div className="flex flex-col items-start">
          <span className="font-semibold text-sm">{m.name}</span>
        </div>

        <div className="flex flex-col items-end duration-300 group-hover:-translate-x-8">
          <span className="text-xs font-bold">{isWearOut ? "Wear out" : "Time out"}</span>
          <span className="text-xs">{isWearOut ? m.wear_out_hours : m.time_out_days} {isWearOut ? "Hrs." : "Days"}</span>
        </div>

        <button
          className="absolute right-0 invisible duration-300 group-hover:visible group-hover:opacity-100 opacity-0"
          onClick={() => onDelete()}
        >
          <i className="fa-solid fa-trash text-red-500"></i>
        </button>
      </div>
    </MaterialCard >
  </div >
}