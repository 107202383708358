import Aircraft from '../models/aircraft.model';
import AircraftType from '../models/aircraft_type.model';
import Flight from '../models/flights.model';
import MaintenanceItem from '../models/maintenance_item.model';
import Milestone from '../models/milestone.model';
import { default as Permissions } from '../models/user.model';
import { getUser, isUserAdmin } from './authentication';
import firebase_app from './firebase_app';
import {
  CollectionReference,
  DocumentData,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';

const db = getFirestore(firebase_app);

async function createDoc(
  collection: CollectionReference<DocumentData>,
  data: object,
) {
  const doc = await addDoc(collection, data);
  return doc.id;
}

async function defineDoc(
  collection: CollectionReference<DocumentData>,
  data: object,
  id: string,
) {
  await setDoc(doc(collection, id), data);
}

async function getAll<T>(
  collection: CollectionReference<DocumentData>,
  idKey: string = 'id',
) {
  const snapshot = await getDocs(collection);
  return snapshot.docs.map(
    d => ({ [idKey]: d.id, ...d.data() } as unknown as T),
  );
}

async function getAllWhere<T>(
  collection: CollectionReference<DocumentData>,
  field: string,
  value: string,
  idKey: string = 'id',
) {
  const filter = query(collection, where(field, '==', value));
  const snapshot = await getDocs(filter);
  return snapshot.docs.map(
    d => ({ [idKey]: d.id, ...d.data() } as unknown as T),
  );
}

async function getOne<T>(
  collection: CollectionReference<DocumentData>,
  id: string,
  idKey: string = 'id',
) {
  const d = await getDoc(doc(collection, id));
  const data = d.data();

  if (!data) return undefined;

  return { [idKey]: d.id, ...data } as unknown as T;
}

async function update(
  collection: CollectionReference<DocumentData>,
  data: object,
  id: string,
) {
  await updateDoc(doc(collection, id), data);
}

const permissionsCollection = collection(db, 'permissions');

export function getUserPermissions(userId: string) {
  return getOne<Permissions>(permissionsCollection, userId);
}

export function getAllUserPermissions() {
  return getAll<Permissions>;
}

export async function updateUserPermissions(
  userId: string,
  field: 'isAdmin' | 'isAuthorized',
  isEnabled: boolean,
) {
  if (!isUserAdmin()) return Promise.reject('Not authorized');

  const currentPermissions = await getUserPermissions(userId);

  if (currentPermissions) {
    await update(permissionsCollection, { [field]: isEnabled }, userId);
  } else {
    await defineDoc(permissionsCollection, { [field]: isEnabled }, userId);
  }
}

const aircraftCollection = collection(db, 'aircraft');
const aircraftTypeCollection = collection(db, 'aircraft_type');

// Aircraft
export function getAircraft() {
  if (isUserAdmin()) {
    return getAll<Aircraft>(aircraftCollection, 'registrationNumber');
  }

  const currentUser = getUser();
  if (!currentUser) {
    return Promise.reject('User not logged in');
  }

  return getAllWhere<Aircraft>(
    aircraftCollection,
    'owner.email',
    currentUser.email!,
    'registrationNumber',
  );
}

export function getAircraftById(id: string) {
  return getOne<Aircraft>(aircraftCollection, id, 'registrationNumber');
}

export function createAircraft(aircraft: Aircraft) {
  return defineDoc(
    aircraftCollection,
    aircraft,
    aircraft.registrationNumber ?? '',
  );
}

export function updateAircraft(id: string, aircraft: Aircraft) {
  return update(aircraftCollection, aircraft, id);
}

export async function deleteAircraft(registrationNumber: string) {
  try {
    const milestones = await getMilestones(registrationNumber);
    const flights = await getFlights(registrationNumber);

    await Promise.all(
      milestones.map(m => deleteMilestone(registrationNumber, m.id!)),
    );
    await Promise.all(
      flights.map(f => deleteFlight(registrationNumber, f.id!)),
    );
    await deleteDoc(doc(aircraftCollection, registrationNumber));
  } catch {
    return false;
  }

  return true;
}

// Aircraft Types
export function getAircraftTypes() {
  return getAll<AircraftType>(aircraftTypeCollection);
}

export function getAircraftTypeById(id: string) {
  return getOne<AircraftType>(aircraftTypeCollection, id);
}

export function createAircraftType(aircraftType: AircraftType) {
  delete aircraftType.id;
  return createDoc(aircraftTypeCollection, aircraftType);
}

export function updateAircraftType(id: string, aircraftType: AircraftType) {
  delete aircraftType.id;
  return update(aircraftTypeCollection, aircraftType, id);
}

export async function deleteAircraftType(id: string) {
  try {
    const maintenance_items = await getMaintenanceItems(id);

    await Promise.all(maintenance_items.map(m => deleteMilestone(id, m.id!)));
    await deleteDoc(doc(aircraftTypeCollection, id));

    return true;
  } catch {}

  return false;
}

// Maintenance Items
function maintenanceCollection(aircraftTypeId: string) {
  return collection(
    doc(aircraftTypeCollection, aircraftTypeId),
    'maintenance_items',
  );
}

export function getMaintenanceItems(aircraftTypeId: string) {
  return getAll<MaintenanceItem>(maintenanceCollection(aircraftTypeId));
}

export function createMaintenanceItem(
  aircraftTypeId: string,
  maintenanceItem: MaintenanceItem,
) {
  delete maintenanceItem.id;
  return createDoc(maintenanceCollection(aircraftTypeId), maintenanceItem);
}

export function deleteMaintenanceItem(aircraftTypeId: string, itemId: string) {
  return deleteDoc(doc(maintenanceCollection(aircraftTypeId), itemId));
}

// Milestones
function milestoneCollection(registrationNumber: string) {
  return collection(doc(aircraftCollection, registrationNumber), 'milestone');
}

export function getMilestones(registrationNumber: string) {
  return getAll<Milestone>(milestoneCollection(registrationNumber));
}

export function createMilestone(aircraftId: string, milestone: Milestone) {
  delete milestone.id;
  return createDoc(milestoneCollection(aircraftId), milestone);
}

export function deleteMilestone(
  registrationNumber: string,
  milestoneId: string,
) {
  return deleteDoc(doc(milestoneCollection(registrationNumber), milestoneId));
}

// Flights
function flightsCollection(registrationNumber: string) {
  return collection(doc(aircraftCollection, registrationNumber), 'flights');
}

export function getFlights(registrationNumber: string) {
  return getAll<Flight>(flightsCollection(registrationNumber));
}

export function deleteFlight(registrationNumber: string, flightId: string) {
  return deleteDoc(doc(flightsCollection(registrationNumber), flightId));
}
