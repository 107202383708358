import { useState } from "react"
import Modal from "../../../components/modal"
import MaintenanceItem from "../../../models/maintenance_item.model"
import Milestone from "../../../models/milestone.model"

export default function AddMaintenanceItemModal(props: {
  maintenanceItems: MaintenanceItem[],
  airframeHours: number,
  onSubmit: (item: Milestone) => void,
  onDismiss: () => void
}) {
  const [selectedItem, setSelectedItem] = useState<MaintenanceItem>()
  const [timeoutDate, setTimeoutDate] = useState(new Date())
  const [wearOutHours, setWearOutHours] = useState(props.airframeHours)

  const isWearOut = selectedItem?.type === 'wear-out'
  const isTimeOut = selectedItem?.type === 'time-out'

  return <Modal>
    <div className="rounded shadow bg-white p-4 flex flex-col w-full max-w-lg space-y-2">
      <div className="flex flex-row relative w-full items-center justify-center">
        <i
          onClick={props.onDismiss}
          className="cursor-pointer fa-regular fa-circle-xmark absolute left-0"></i>
        <span className="font-bold text-lg">Add Maintenance Milestone</span>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          props.onSubmit({
            is_active: true,
            maintenance_item_id: selectedItem?.id ?? "",
            time_out_start: timeoutDate?.toISOString(),
            wear_out_start: wearOutHours,
            warning_sent: false
          })
        }}
        className="flex flex-col"
      >
        <div className="flex flex-row space-x-4">
          <div className="flex-1 flex flex-col justify-start">
            <label className="text-xs font-light">Maintenance Item</label>
            <select
              required
              className="rounded p-1 border border-gray-300 text-sm w-full"
              value={selectedItem?.id}
              onChange={(e) => {
                const id = e.target.value
                const selectedItem = props.maintenanceItems.find((m) => m.id === id)

                setSelectedItem(selectedItem)

                if (selectedItem?.type === 'time-out') {
                  setTimeoutDate(new Date())
                } else {
                  setWearOutHours(props.airframeHours)
                }
              }}
            >
              {!selectedItem && <option value="">Select one...</option>}
              {props.maintenanceItems.map((m) => <option key={m.id} value={m.id}>{m.name}</option>)}
            </select>
          </div>

          <div className="flex-1 flex flex-col justify-start">
            <label className="text-xs font-light">{isTimeOut && "Start date"}{isWearOut && "Starting Airframe Hours"}</label>
            {isWearOut && <input
              required
              type="number"
              min="0"
              value={wearOutHours}
              onChange={(e) => {
                setWearOutHours(+e.target.value)
              }}
              className="rounded p-1 border border-gray-300 flex-1 text-sm" />
            }
            {isTimeOut && <input
              required
              type="date"
              value={timeoutDate && getFormattedDate(timeoutDate)}
              onChange={(e) => {
                const newDate = new Date(e.target.value);
                if (!isNaN(newDate.getTime())) {
                  setTimeoutDate(newDate)
                }
              }}
              className="rounded p-1 border border-gray-300 flex-1 text-sm" />
            }

          </div>
        </div>

        <input type="submit" value="Create" className="bg-blue-500 rounded shadow py-1 mt-4 text-white font-bold text-sm cursor-pointer" />
      </form>
    </div>
  </Modal >
}

function getFormattedDate(date: Date) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return year + "-" + month + '-' + day;
}