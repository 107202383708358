// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBOruWu6uPfbY96SVV665bzz1U9fLfhEJM',
  authDomain: 'spearman-aircraft-tracker.firebaseapp.com',
  projectId: 'spearman-aircraft-tracker',
  storageBucket: 'spearman-aircraft-tracker.appspot.com',
  messagingSenderId: '1088405173222',
  appId: '1:1088405173222:web:9e92b6642f276f35801c27',
};

// Initialize Firebase
export default initializeApp(firebaseConfig);
