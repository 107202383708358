

interface SearchBarProps {
  placeholder: string;
  value: string;
  onChange: (newValue: string) => void
}

export function SearchBar(props: SearchBarProps) {
  return <div className="rounded-full bg-gray-100 w-full border border-gray-200 flex flex-row items-center">
    <input
      className="focus:outline-none flex-1 py-2 px-4 bg-transparent"
      placeholder={props.placeholder}
      type="text"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
    />
    <i className="fa-solid fa-magnifying-glass pr-4 text-gray-400"></i>
  </div>
}