import { useEffect, useState } from 'react';
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Loader from './components/loader';
import { DataCtx } from './context';
import logo from './logo.svg';
import Aircraft from './models/aircraft.model';
import AircraftType from './models/aircraft_type.model';
import User from './models/user.model';
import { AircraftDetails } from './pages/aircraft_tab/aircraft_details';
import AircraftTab from './pages/aircraft_tab/index';
import AircraftTypeDetails from './pages/aircraft_types_tab/aircraft_type_details';
import AircraftTypesTab from './pages/aircraft_types_tab/index';
import Landing from './pages/authentication/landing';
import UsersTab from './pages/users_tab';
import { AuthState, AuthStatus, isUserAdmin, isUserEnabled, useAuth } from './repository/authentication';
import { getAircraft, getAircraftTypes } from './repository/database';

const styles = {
  button: "font-semibold text-sm px-4 py-2 rounded border-gray-200 hover:bg-gray-200 transition-all duration-300"
}

enum Tab {
  aircraft = "aircraft",
  aircraft_types = "aircraft_types",
  users = "users"
}

function App() {
  const authState = useAuth();
  const [allAircraft, setAllAircraft] = useState<Aircraft[]>();
  const [aircraftTypes, setAircraftTypes] = useState<AircraftType[]>();
  const [users, setUsers] = useState<User[]>();

  const userIsEnabled = isUserEnabled();

  const {
    status: authStatus,
    isSignedIn
  } = authState;

  useEffect(() => {
    if (authStatus === AuthStatus.COMPLETE && isSignedIn && userIsEnabled) {
      getAircraft().then(setAllAircraft)
      getAircraftTypes().then(setAircraftTypes)
    }
  }, [authStatus, userIsEnabled, isSignedIn])

  if (authStatus !== AuthStatus.COMPLETE) return <Loader />

  if (!userIsEnabled && isSignedIn) return <div className='flex flex-col justify-center items-center w-screen h-screen'>
    <div className='my-12 flex flex-col items-center w-full max-w-lg font-bold'>
      Your account is not enabled. Please reach out to <a className="text-blue-500 underline" href="mailto:james.spearman@spearmanaircraft.com">Spearman Aircraft</a> to enable your account.
    </div>
    <button className='bg-gray-100 rounded font-bold px-4 py-2 shadow hover:shadow-lg cursor-pointer transition-all' onClick={authState.signOut}>Log Out</button>
  </div>

  return (
    <DataCtx.Provider value={{
      aircraft: [allAircraft, setAllAircraft],
      aircraftTypes: [aircraftTypes, setAircraftTypes],
      users: [users, setUsers]
    }}>
      <BrowserRouter>
        <div style={{ fontFamily: "sans-serif" }} className="text-gray-800 flex flex-col justify-center items-center">
          <header className='flex flex-row items-center w-full p-4 shadow-md mb-4' >
            <div className='flex-1 flex flex-row items-center'>
              <img className='bg-gray-800 w-12 h-12 rounded-full' alt="" src={logo} />
              <span className='ml-2 text-xl font-bold'>Spearman Aircraft</span>
            </div>

            {isSignedIn && tabBar(authState)}
          </header>


          {isSignedIn && authenticatedRoutes()}
          {!isSignedIn && unauthenticatedRoutes()}
        </div >
      </BrowserRouter>
    </DataCtx.Provider >
  );
}

function tabBar(authState: AuthState) {
  return <>
    {authState.isAdmin && menuTab(Tab.aircraft)}
    {authState.isAdmin && menuTab(Tab.aircraft_types)}
    {authState.isAdmin && menuTab(Tab.users)}
    <button key="logout" className={styles.button} onClick={authState.signOut} >Log out</button>
  </>;
}

function authenticatedRoutes() {
  const userIsAdmin = isUserAdmin();

  return <Routes>
    <Route path="aircraft/:regNum/details" element={<AircraftDetails />} />
    <Route path="aircraft" element={<AircraftTab />} />

    {userIsAdmin && <Route path="users" element={<UsersTab />} />}

    {<Route path="aircraft_types/:aircraftTypeId/details" element={<AircraftTypeDetails />} />}
    {userIsAdmin && <Route path="aircraft_types" element={<AircraftTypesTab />} />}
    <Route path="*" element={<Navigate to="aircraft" replace />} />
  </Routes>
}

function unauthenticatedRoutes() {
  return <Routes>
    <Route path="landing" element={<Landing />} />
    <Route path="*" element={<Navigate to="landing" replace />} />
  </Routes>
}

function menuTab(key: Tab) {
  let data: { name: string, route: string }

  switch (key) {
    case Tab.aircraft:
      data = { name: "Aircraft", route: "/aircraft" }
      break;
    case Tab.aircraft_types:
      data = { name: "Aircraft Types", route: "/aircraft_types" }
      break;
    case Tab.users:
      data = { name: "Users", route: "/users" }
      break;
  }

  return (
    <Link
      key={key}
      className={styles.button}
      to={data.route}
    >
      {data.name}
    </Link>
  )
}

export default App;
