import { useContext, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/loader";
import MaterialCard from "../../components/material_card";
import NavHeader from "../../components/nav_header";
import { SearchBar } from "../../components/search_bar";
import { DataCtx } from "../../context";
import AircraftType from "../../models/aircraft_type.model";
import CreateAircraftTypeModal from "./modals/create_aircraft_type_modal";


export default function AircraftTypesTab() {
  const context = useContext(DataCtx);
  const [aircraftTypes, setAircraftTypes] = context.aircraftTypes;
  const [isCreating, setIsCreating] = useState(false);
  const [searchFilter, setSearchFilter] = useState("")

  const navigator = useNavigate()

  const filteredAircraft = useMemo(
    () => {
      if (!searchFilter && aircraftTypes) return aircraftTypes;

      return (aircraftTypes ?? []).filter((at) => at.manufacturer.includes(searchFilter) || at.model.includes(searchFilter))
    },
    [aircraftTypes, searchFilter]
  )

  if (!aircraftTypes) {
    return <Loader />
  }

  return (
    <div className="flex flex-col items-stretch justify-center m-4 max-w-lg w-full">
      <NavHeader
        shouldRenderBack={false}
        shouldRenderAdd={true}
        onClickAdd={() => setIsCreating(true)}
        title="Aircraft Types"
      />

      {!aircraftTypes && <span>Loading...</span>}


      <div className="w-full px-8 mb-2">
        <SearchBar
          value={searchFilter}
          onChange={(newValue) => setSearchFilter(newValue)}
          placeholder="Search by manufacturer or model"
        />
      </div>

      {filteredAircraft.map(renderAircraftTypeCard)}

      {isCreating && <CreateAircraftTypeModal
        onDismiss={() => setIsCreating(false)}
        onCreate={(at) => {
          setAircraftTypes!([...(aircraftTypes ?? []), at])
          setIsCreating(false);
          navigator(`${at.id}/details`)
        }} />}
    </div>
  )
}

function renderAircraftTypeCard(aircraftType: AircraftType) {
  return (
    <Link to={`${aircraftType.id}/details`} key={aircraftType.id}>
      <MaterialCard>
        <div className="flex flex-row justify-between items-stretch w-full">
          <span className="font-bold text-sm">{aircraftType.manufacturer} {aircraftType.model}</span>

          <i className='fa-solid fa-chevron-right text-blue-500'></i>
        </div>
      </MaterialCard>
    </Link>
  )
}









