import { useContext, useState } from "react";
import { DataCtx } from "../../../context";
import AircraftType from "../../../models/aircraft_type.model";
import { useAuth } from "../../../repository/authentication";
import { createAircraft, getAircraftTypeById } from "../../../repository/database";
import { getAircraftTypeId } from "../../../repository/functions";

interface NewAircraftModalProps {
  setModalActive: (active: boolean) => void;
  onCreate: () => void;
}

const inputStyle = "rounded border border-1 p-1 text-sm"

export default function NewAircraftModal(props: NewAircraftModalProps) {
  const { setModalActive, onCreate } = props;

  const { user, isAdmin } = useAuth();

  const [aircraftType, setAircraftType] = useState<AircraftType>();
  const [regNumber, setRegNumber] = useState<string>();

  const setTypeDetails = (type: AircraftType | undefined, reg: string | undefined) => {
    setAircraftType(type);
    setRegNumber(reg);
  }

  return <div className="absolute left-0 right-0 top-0 bottom-0 bg-black bg-opacity-50">
    <div className="flex h-screen">
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (!aircraftType || !regNumber) return;

          const {
            nameFirst,
            nameLast,
            email,
            phone,
            total_hours,
            registration_number,
            manufacture_year,
            aircraft_type_id
          } = e.currentTarget.elements as any;

          const aircraftDTO = {
            owner: {
              nameFirst: nameFirst.value,
              nameLast: nameLast.value,
              email: email.value,
              phone: phone.value
            },
            registrationNumber: registration_number.value,
            year: manufacture_year.value,
            aircraft_type_id: aircraft_type_id.value,
            total_hours: +total_hours.value,
            base_hours: +total_hours.value,
            milestones: []
          };

          await createAircraft(aircraftDTO);
          onCreate();
        }}
        className="m-auto max-w-xl p-8 rounded shadow-lg bg-white flex flex-col space-y-4"
      >

        <div className="flex row relative mb-4">
          <span className="text-center flex-1 basis-0 text-lg font-bold">Create Aircraft</span>
          <button
            className="absolute right-0"
            onClick={() => setModalActive(false)}
          ><i className="fa-regular fa-circle-xmark"></i></button>
        </div>

        <fieldset className="space-y-2">
          <legend className="text-normal font-bold">Customer</legend>
          <div className="flex flex-row space-x-4 flex-wrap">
            <input required
              name="nameFirst"
              type="text"
              className={"flex-1 " + inputStyle}
              placeholder="First name"
              data-lpignore="true"
              autoComplete="off"
            />
            <input required
              name="nameLast"
              type="text"
              className={"flex-1 " + inputStyle}
              placeholder="Last name"
              data-lpignore="true"
              autoComplete="off"
            />
          </div>
          <div className="flex flex-row space-x-4 flex-wrap items-center">
            <input
              name="phone"
              type="text"
              className={"flex-1 " + inputStyle}
              placeholder="Phone"
              data-lpignore="true"
              autoComplete="off"

            />
            {
              (isAdmin || !user?.email) ? <input
                name="email"
                type="text"
                className={"flex-1 " + inputStyle}
                placeholder="Email"
                data-lpignore="true"
                autoComplete="off"
              />
                : <div>
                  <p className="text-xs font-bold">Email:</p>
                  <span className="text-sm">{user.email}</span>
                  <input
                    name="email"
                    type="hidden"
                    value={user.email}
                  />
                </div>
            }
          </div>
        </fieldset>

        {(aircraftType && regNumber) && renderAircraftDetails(regNumber, aircraftType, setTypeDetails)}
        {
          !aircraftType && <SearchRegistrationNumber onSelectType={setTypeDetails} />
        }
      </form>
    </div>
  </div>
}


type SetAircraftTypeDetailsCallback = (aircraftType: AircraftType | undefined, registrationNumber: string | undefined) => void

interface SearchRegistrationNumberProps {
  onSelectType: SetAircraftTypeDetailsCallback
}

function SearchRegistrationNumber({ onSelectType }: SearchRegistrationNumberProps) {
  const [regNumber, setRegNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const { aircraftTypes: [aircraftTypes, setAircraftTypes] } = useContext(DataCtx)

  return <fieldset className="space-y-2">
    <legend className="text-normal font-bold">Aircraft Details</legend>
    <div className="flex flex-row space-x-4">
      <input
        type="text"
        className={"flex-1 " + inputStyle}
        placeholder="Registration Number"
        value={regNumber}
        onChange={(e) => setRegNumber(e.target.value)}
        data-lpignore="true"
        autoComplete="off"
        disabled={isLoading}
      />
      <button
        className={"text-white font-bold text-sm rounded shadow px-2 " + (regNumber ? "bg-blue-500" : "bg-gray-300")}
        disabled={!regNumber || isLoading}
        onClick={() => {
          if (!regNumber) return;

          setIsLoading(true)
          setErrorMsg(undefined)

          getAircraftTypeId(regNumber)
            .then((typeId) => typeId ? getAircraftTypeById(typeId) : undefined)
            .then((type) => {
              setIsLoading(false)

              if (type) {
                if (aircraftTypes && !aircraftTypes.find((at) => at.id === type.id)) {
                  setAircraftTypes!([...(aircraftTypes ?? []), type])
                }
                onSelectType(type, regNumber)
              } else {
                setErrorMsg("Failed to load details. Provide the full registration number.")
              }
            })
        }}
      >
        <i className="fa-solid fa-magnifying-glass text-xs"></i> Search Aircraft
      </button>
    </div>
    {isLoading && <span className="text-xs font-bold">Loading... This will be quick.<br /></span>}
    {errorMsg && <span className="text-xs text-red-500">{errorMsg}</span>}
  </fieldset>
}

function renderAircraftDetails(registrationNumber: string, aircraftType: AircraftType, onReset: SetAircraftTypeDetailsCallback) {
  return <fieldset className="space-y-4">
    <legend className="text-normal font-bold">
      Aircraft Details <button
        onClick={() => onReset(undefined, undefined)}
        className="text-xs text-red-500 font-bold">{"(Reset Details)"}
      </button>
    </legend>

    <div className="flex flex-row justify-between text-sm">
      <span>Registration Number:</span>
      <span className="font-bold">{registrationNumber}</span>
    </div>
    <div className="flex flex-row justify-between text-sm">
      <span>Aircraft Type:</span>
      <span className="font-bold">{aircraftType.manufacturer} {aircraftType.model}</span>
    </div>

    <div className="flex flex-row space-x-4">
      <input
        type="number"
        name="total_hours"
        className={"flex-1 " + inputStyle}
        placeholder="Flight Hours"
        min={0}
        step={1}
        required
        data-lpignore="true"
        autoComplete="off"
      />
      <input
        type="text"
        name="manufacture_year"
        className={"flex-1 " + inputStyle}
        placeholder="Year"
        data-lpignore="true"
        autoComplete="off"
      />
    </div>

    <input required type="hidden" name="registration_number" value={registrationNumber} />
    <input required type="hidden" name="aircraft_type_id" value={aircraftType.id} />

    <input
      className="w-full p-2 bg-blue-500 rounded shadow text-sm font-bold text-white cursor-pointer"
      type="submit"
      name="Create" />
  </fieldset>
}