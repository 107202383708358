import { getUserPermissions } from './database';
import firebase_app from './firebase_app';
import { EmailAuthProvider, User, getAuth } from 'firebase/auth';
import * as firebaseui from 'firebaseui';
import { useEffect, useState } from 'react';

const auth = getAuth(firebase_app);

let _userIsAdmin = false;
let _userIsEnabled = false;

var ui = new firebaseui.auth.AuthUI(auth);

export function getUser() {
  return auth.currentUser;
}

export function isUserAdmin() {
  return _userIsAdmin;
}

export function isUserEnabled() {
  return _userIsEnabled;
}

export function startAuth() {
  ui.start('#firebaseui-auth-container', {
    signInOptions: [EmailAuthProvider.PROVIDER_ID],
    signInSuccessUrl: '/aircraft',
  });
}

export enum AuthStatus {
  INITIALIZED,
  PENDING,
  COMPLETE,
}

export interface AuthState {
  isSignedIn: boolean;
  status: AuthStatus;
  user: User | undefined;
  isAdmin: boolean;
  signOut: () => Promise<void>;
}

export function useAuth(): AuthState {
  const currentUser = auth.currentUser ?? undefined;
  const currentStatus = currentUser
    ? AuthStatus.PENDING
    : AuthStatus.INITIALIZED;

  const [user, setUser] = useState(currentUser);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSignedIn, setIsSignedIn] = useState(!!currentUser);
  const [status, setStatus] = useState(currentStatus);

  useEffect(() => {
    const cleanup = auth.onAuthStateChanged(user => {
      setUser(user ?? undefined);
      setIsSignedIn(!!user);
      setStatus(AuthStatus.PENDING);
    });
    return cleanup;
  }, []);

  useEffect(() => {
    if (status === AuthStatus.PENDING) {
      if (user) {
        getUserPermissions(user.uid).then(permissions => {
          _userIsAdmin = permissions?.isAdmin ?? false;
          _userIsEnabled = permissions?.isAuthorized ?? false;
          setIsAdmin(_userIsAdmin);
          setStatus(AuthStatus.COMPLETE);
        });
      } else {
        setIsAdmin(false);
        setStatus(AuthStatus.COMPLETE);
      }
    }
  }, [status, user]);

  const signOut = async () => {
    await auth.signOut();
  };

  return { status, user, isSignedIn, isAdmin, signOut };
}
