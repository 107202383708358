import React from "react";

interface MaterialCardProps {
  onClick?: () => void
  isFullWidth?: boolean
}


export default function MaterialCard(props: React.PropsWithChildren<MaterialCardProps>) {
  return <div
    onClick={props.onClick}
    className={`
    rounded-lg shadow border my-2 p-4 bg-white 
    ${props.isFullWidth ? 'w-full' : ''}
    ${props.onClick ? 'hover:shadow-md transition duration-300 cursor-pointer' : ""}
    `}
  >
    {props.children}
  </div >
}