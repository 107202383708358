import Aircraft from '../models/aircraft.model';
import AircraftType from '../models/aircraft_type.model';
import User from '../models/user.model';
import { createContext } from 'react';

type Setter<T> = React.Dispatch<React.SetStateAction<T | undefined>>;
type Store<T> = [T | undefined, Setter<T> | undefined];

interface DataStore {
  aircraft: Store<Aircraft[]>;
  aircraftTypes: Store<AircraftType[]>;
  users: Store<User[]>;
}

export const DataCtx = createContext({
  aircraft: [undefined, undefined],
  aircraftTypes: [undefined, undefined],
  users: [undefined, undefined],
} as DataStore);
