import { useState } from "react";
import MaterialCard from "../../../components/material_card";
import Modal from "../../../components/modal";
import AircraftType from "../../../models/aircraft_type.model";
import { createAircraftType, getAircraftTypeById } from "../../../repository/database";

interface CreateAircraftTypeModalProps {
  onDismiss: () => void
  onCreate: (aircraftType: AircraftType) => void
}

export default function CreateAircraftTypeModal({ onCreate, onDismiss }: CreateAircraftTypeModalProps) {
  const [manufacturer, setManufacturer] = useState("");
  const [model, setModel] = useState("");

  return <Modal>
    <MaterialCard>
      <div className="flex flex-col min-w-md space-y-2">
        <div className="flex flex-row relative justify-center items-center w-full">
          <i
            onClick={onDismiss}
            className="cursor-pointer fa-regular fa-circle-xmark left-0 absolute"></i>
          <span className="font-bold text-lg">Create Aircraft Type</span>
        </div>

        <form onSubmit={async (e) => {
          e.preventDefault();

          const id = await createAircraftType({ manufacturer, model });
          const aircraftType = await getAircraftTypeById(id);

          onCreate(aircraftType!)
        }}>
          <div className="flex flex-row space-x-2">
            <input
              type="text"
              value={manufacturer}
              onChange={(e) => setManufacturer(e.target.value)}
              className="border border-gray-300 rounded p-1 text-sm"
              placeholder="Manufacturer"
            />
            <input
              type="text"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              className="border border-gray-300 rounded p-1 text-sm"
              placeholder="Model"
            />
          </div>
          <input type="submit" className="w-full rounded p-1 mt-4 bg-blue-500 shadow cursor-pointer text-sm text-white font-bold" />
        </form>
      </div>
    </MaterialCard>
  </Modal>
}