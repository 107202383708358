
import { useContext, useState } from "react";
import Loader from "../../../components/loader";
import MaterialCard from "../../../components/material_card";
import Modal from "../../../components/modal";
import { DataCtx } from "../../../context";
import User from '../../../models/user.model';
import { updateUserPermissions } from "../../../repository/database";
import { deleteUser } from "../../../repository/functions";


export interface EditUserProperties {
  user: User;
  onDismiss: () => void;
}

export default function EditUserModal(props: EditUserProperties) {
  const dataCtx = useContext(DataCtx);
  const [users, setUsers] = dataCtx.users;

  const [isConfirmDelete, setIsConfirmDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(props.user.isAdmin);
  const [isAuthorized, setIsAuthorized] = useState(props.user.isAuthorized);

  let content: JSX.Element;

  if (isLoading) {
    content = <Loader />
  } else if (isConfirmDelete) {
    content = <div className="flex flex-col items-center w-full space-y-2">
      <p className="text-sm text-center w-2/3 font-bold">Are you sure you want to delete {props.user.email}'s account?</p>

      <div className="flex flex-row w-full space-x-2 ">
        <button
          className="font-bold text-white text-sm bg-blue-500 w-full border text-center rounded-md mt-4 py-1 flex-1"
          onClick={() => setIsConfirmDelete(false)}
        >
          Cancel
        </button>
        <button
          className="font-bold text-red-500 text-sm border-red-500 border w-full text-center rounded-md mt-4 py-1 flex-1"
          onClick={async () => {
            setIsLoading(true);
            const result = await deleteUser(props.user.uid)
            setIsLoading(false);

            if (result) {
              const newUsers = users?.filter((u) => u.uid !== props.user.uid)
              setUsers!(newUsers ?? [])
              props.onDismiss();
            }
          }}
        >
          Delete User
        </button>
      </div>
    </div>
  } else {
    const updatePermission = (field: "isAdmin" | "isAuthorized", enabled: boolean) => {
      if (field === "isAdmin") setIsAdmin(enabled);
      else setIsAuthorized(enabled)

      updateUserPermissions(props.user.uid, field, enabled);
      // Update context
      const newUsers = users?.map((u) => {
        if (u.uid === props.user.uid) {
          return { ...u, [field]: enabled }
        } else {
          return u;
        }
      });
      if (newUsers) setUsers!(newUsers ?? [])
    }
    content = <div className="flex flex-col items-center w-full space-y-2">
      <span className="font-bold text-md">{props.user.email}</span>

      <div className="flex flex-row w-1/2 relative items-center justify-between">
        <span className="left-0 text-sm">Is Admin?</span>
        <ControlledToggle
          isChecked={isAdmin}
          onChange={(newValue) => updatePermission("isAdmin", newValue)}
        />
      </div>

      <div className="flex flex-row w-1/2 relative items-center justify-between">
        <span className="left-0 text-sm">Is Authorized?</span>
        <ControlledToggle
          isChecked={isAuthorized}
          onChange={(newValue) => updatePermission("isAuthorized", newValue)}
        />
      </div>

      <div className="flex flex-row space-x-2 w-2/3">
        <button
          className="font-bold text-white text-sm bg-blue-500 w-full border text-center rounded-md mt-4 py-1 flex-1"
          onClick={props.onDismiss}
        >
          Dismiss
        </button>
        <button
          className="font-bold text-red-500 text-sm border-red-500 border w-full text-center rounded-md mt-4 py-1 flex-1"
          onClick={() => setIsConfirmDelete(true)}
        >
          Delete
        </button>
      </div>
    </div>
  }

  return <Modal>
    <div className="w-full max-w-md">
      <MaterialCard isFullWidth={true} >
        {content}
      </MaterialCard>
    </div >
  </Modal >
}

interface ControlledToggleProps {
  onChange: (newValue: boolean) => void;
  isChecked: boolean;
}
function ControlledToggle({ isChecked, onChange }: ControlledToggleProps) {
  return <label className="relative inline-flex items-center cursor-pointer">
    <input type="checkbox" value="" checked={isChecked} className="sr-only peer" onChange={(event) => {
      const newValue = event.target.checked;
      onChange(newValue);
    }} />
    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 
    dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:before:translate-x-full 
    peer-checked:before:border-white before:content-[''] before:absolute before:top-[2px] before:left-[2px] 
    before:bg-white before:border-gray-300 before:border before:rounded-full before:h-5 before:w-5 
    before:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
    ></div>
  </label>
}