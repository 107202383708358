import { useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/loader";
import NavHeader from "../../components/nav_header";
import { SearchBar } from "../../components/search_bar";
import { DataCtx } from "../../context";
import Aircraft from "../../models/aircraft.model";
import AircraftType from "../../models/aircraft_type.model";
import { getAircraft } from "../../repository/database";
import NewAircraftModal from "./modals/new_aircraft_modal";

function AircraftTab() {
  const [isModalActive, setIsModalActive] = useState(false);
  const [searchFilter, setSearchFilter] = useState("")

  const dataCtx = useContext(DataCtx);

  const [aircraft, setAircraft] = dataCtx.aircraft;
  const [aircraftTypes] = dataCtx.aircraftTypes;

  const atMap = useMemo(
    () => (aircraftTypes ?? []).reduce((acc, it) => (acc.set(it.id!, it)), new Map<string, AircraftType>()),
    [aircraftTypes],
  )

  const filteredAircraft = useMemo(
    () => {
      if (!searchFilter && aircraft) return aircraft;
      return (aircraft ?? []).filter(
        (a) => [a.owner.nameFirst, a.owner.nameLast, a.registrationNumber, atMap.get(a.aircraft_type_id)?.manufacturer]
          .some((v) => v?.includes(searchFilter)),
      )
    },
    [aircraft, searchFilter, atMap]
  )

  if (!aircraft || !aircraftTypes) {
    return <Loader />
  }

  return (
    <div className='m-4 flex flex-col items-center w-full max-w-md'>
      <NavHeader
        shouldRenderBack={false}
        shouldRenderAdd={true}
        onClickAdd={() => setIsModalActive(true)}
        title="Aircraft"
      />

      <div className="w-full px-8 mb-2">
        <SearchBar
          value={searchFilter}
          onChange={(newValue) => setSearchFilter(newValue)}
          placeholder="Search by owner, registration, or type "
        />
      </div>

      {
        (aircraft?.length ?? 0) === 0 && <div className='my-12 flex flex-col items-center text-center w-full max-w-lg font-light'>
          You do not have any aircraft. Please add one to get started.
        </div>
      }


      {filteredAircraft.map(a => renderAircraftCard(a, atMap.get(a.aircraft_type_id)))}

      {isModalActive && <NewAircraftModal setModalActive={setIsModalActive} onCreate={() => {
        getAircraft().then(setAircraft)
        setIsModalActive(false)
      }} />}
    </div >
  )
}

function renderAircraftCard(aircraft: Aircraft, aircraftType?: AircraftType) {
  return (
    <Link
      key={aircraft.registrationNumber}
      to={`${aircraft.registrationNumber}/details`}
      className='max-w-xl w-full rounded-lg 
      shadow-sm border my-2 p-4 flex flex-row 
      hover:shadow-md transition duration-300
      items-center'
    >
      <div className='flex flex-col items-start flex-1'>
        <span className='font-bold'>{aircraft.owner.nameFirst} {aircraft.owner.nameLast}</span>
        <span className='text-xs'>{aircraft.registrationNumber}</span>
        <span className='text-xs'>{aircraft.year} {aircraftType?.manufacturer} {aircraftType!.model}</span>
      </div>
      <i className='fa-solid fa-chevron-right text-blue-500'></i>
    </Link >
  )
}


export default AircraftTab