import { useState } from "react";
import Modal from "../../../components/modal";
import MaintenanceItem from "../../../models/maintenance_item.model";

type ItemType = 'wear-out' | 'time-out';

interface CreateMaintenanceItemModalProps {
  setShowModal: (isActive: boolean) => void
  onCreateItem: (item: MaintenanceItem) => Promise<void>
}

export default function CreateMaintenanceItemModal({ setShowModal, onCreateItem }: CreateMaintenanceItemModalProps) {
  const [type, setType] = useState<ItemType>()
  const [name, setName] = useState("");
  const [wear_out_hours, setWoHours] = useState(0);
  const [time_out_days, setToDays] = useState(0);

  return <Modal>
    <div className="rounded shadow bg-white p-4 flex flex-col w-full max-w-md space-y-4">
      <div className="relative self-center w-full flex flex-row justify-center items-center">
        <i onClick={() => setShowModal(false)} className="absolute left-0 fa-regular fa-circle-xmark"></i>
        <span className="font-bold text-lg">Create Maintenance Item</span>
      </div>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          await onCreateItem({
            name,
            wear_out_hours,
            time_out_days,
            type: type!,
          })
        }}
        className="flex flex-col space-y-2 self-center "
      >
        <input
          className="w-full rounded border border-gray-300 p-1 text-sm"
          type="text"
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          required
          autoComplete="false"
        />
        <div className="w-full flex flex-row text-sm space-x-2">
          <select
            className="flex-1 rounded border border-gray-300 p-1"
            value={type}
            onChange={(e) => setType(e.target.value as ItemType)}
            required
          >
            {!type && <option value={""}>Select one...</option>}
            <option value={'wear-out'}>Wear out</option>
            <option value={'time-out'}>Time out</option>
          </select>
          {type && <input
            value={type === "wear-out" ? wear_out_hours : time_out_days}
            onChange={(e) => {
              if (type === "wear-out") {
                setWoHours(+e.target.value)
                setToDays(0)
              } else {
                setWoHours(0)
                setToDays(+e.target.value)
              }
            }}
            className="flex-1 rounded border border-gray-300 p-1"
            type="number"
            required
            placeholder={type === "wear-out" ? "Wear out hours" : "Time out days"}
          />
          }
        </div>

        <input type="submit" className="w-full bg-blue-500 rounded shadow-sm cursor-pointer p-1 text-sm text-white font-bold" />
      </form>
    </div>
  </Modal>
}



